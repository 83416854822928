





































import { Component, Mixins, Ref } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";
import TimeSheetReportFileModule from "@/store/modules/time-sheet-report-file-module";
import DownloadMixin from "@/mixins/download-mixin";
import { downloadReport } from "@/services/time-sheet-report-file.service";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { DialogInterface } from "@/types/component.type";
import TableComponent from "@/components/TableComponent.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";
import { DataTableOption } from "@/types/data-table.type";

@Component({
  components: {
    ConfirmationDialog,
    TableComponent,
    SaveButton,
  },
})
export default class TimeSheetReportTable extends Mixins(DownloadMixin) {
  private headers: DataTableHeader[] = [
    {
      text: "Project",
      value: "projectDisplay",
      sortable: false,
    },
    {
      text: "Month",
      value: "date",
    },
    {
      text: "Client",
      value: "clientName",
    },
    {
      text: "Download",
      value: "download",
      align: "center",
      sortable: false,
    },
    {
      text: "Action",
      value: "action",
      sortable: false,
    },
  ];
  private reportId: number | null = null;

  @Ref()
  private deleteDialog!: DialogInterface;

  private get timeSheetReportFiles() {
    return TimeSheetReportFileModule.timeSheetReportFiles;
  }

  private async download(id: number) {
    try {
      await this.downloadFile(() => downloadReport(id));
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Download report successfully!",
    });
  }

  private openDeleteReportDialog(id: number) {
    this.reportId = id;
    this.deleteDialog.openDialog();
  }

  get reportCount(): number {
    return TimeSheetReportFileModule.timeSheetReportCount;
  }

  private async deleteReport() {
    try {
      await TimeSheetReportFileModule.deleteTimeSheetReportFile(this.reportId!);
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Delete report successfully!",
    });
  }

  private optionChanged(newOptions: DataTableOption) {
    TimeSheetReportFileModule.getTimeSheetReportFiles(newOptions);
  }
}
