import { AxiosResponse } from "axios";
import { Component, Vue } from "vue-property-decorator";

@Component
class DownloadMixin extends Vue {
  public downloadFile(func: () => Promise<AxiosResponse>) {
    return func().then((response: AxiosResponse) => {
      const blob: Blob = new Blob([response.data], { type: response.headers["content-type"] });
      const fileURL: string = window.URL.createObjectURL(blob);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;

      const fileNamePattern: RegExp = /filename\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/g;
      const groups: RegExpExecArray | null = fileNamePattern.exec(response.headers["content-disposition"]);
      const fileName = groups![1];

      fileLink.setAttribute("download", fileName);
      document.body.appendChild(fileLink);

      // @ts-ignore
      // when running in cypress, don't download the file
      if (window.Cypress) {
        return;
      }

      fileLink.click();
      fileLink.remove();
    });
  }
}

export default DownloadMixin;
