





































































































import { Component, Prop, Vue } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";
import { LeaveReasonModule } from "@/store/modules/leave-reason-module";
import { LeaveReasonInterface, LeaveRequestInterface } from "@/types/leave-request.type";
import { updateLeaveRequest } from "@/services/leave-request.service";
import CloseButton from "@/components/buttons/CloseButton.vue";
import DeleteButton from "@/components/buttons/DeleteButton.vue";
import { UserModule } from "@/store/modules/user-module";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";
import { NotificationModule } from "@/store/modules/notification-module";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import { mixins } from "vue-class-component";
import { getDaysOffInRange } from "@/services/day-off.service";

@Component({
  components: {
    ConfirmationDialog,
    Header,
    SaveButton,
    CloseButton,
    DeleteButton,
  },
})
export default class LeaveApprovalForm extends mixins(DurationHelperMixins) {
  @Prop({
    default: {},
    type: Object,
  })
  private data!: LeaveRequestInterface;
  @Prop({
    default: 0,
    type: Number,
  })
  private approvedLeaveNumber!: number;
  @Prop({
    default: 0,
    type: Number,
  })
  private leaveLimit!: number;

  private workingDaysRequested = 0;
  private leaveErrorMsg = "";

  async created() {
    try {
      let daysOff = await getDaysOffInRange(new Date(this.data.startDate), new Date(this.data.endDate));
      this.workingDaysRequested = (this.getLeaveDaysRequested() - daysOff) as number;
      if (this.workingDaysRequested + this.approvedLeaveNumber > this.leaveLimit)
        this.leaveErrorMsg = "Approving leave request would exceed the users allocated leave days limit";
    } catch (e) {
      // Most likely date parsing error
      this.leaveErrorMsg = "Error parsing dates";
    }
  }

  private getLeaveDaysRequested(): number {
    let start = new Date(this.data.startDate);
    let end = new Date(this.data.endDate);
    return (end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24) + 1;
  }

  private get leaveReasons(): LeaveReasonInterface[] {
    return LeaveReasonModule.leaveReasons;
  }

  private get requester() {
    return this.data?.createdByInfo?.userDisplay;
  }

  private get requesterEmail() {
    return this.data?.createdByInfo?.email;
  }

  private goBack() {
    this.$router.push({ name: "Leave Request Admin" });
  }

  private async approveRequest() {
    await updateLeaveRequest({
      id: this.data.id!,
      approved: true,
      approved_by: UserModule.userProfile.realId,
    });
    await NotificationModule.getLeaveRequestCount();
    await this.$router.push({ name: "Leave Request Admin" });
  }

  private async declineRequest() {
    await updateLeaveRequest({
      id: this.data.id!,
      approved: false,
      approved_by: UserModule.userProfile.realId,
    });
    await NotificationModule.getLeaveRequestCount();
    await this.$router.push({ name: "Leave Request Admin" });
  }
}
