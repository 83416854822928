import { getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { TimeSheetReportFileInterface } from "@/types/time-sheet-report-file.type";
import { deleteReport, getTimeSheetReportFiles } from "@/services/time-sheet-report-file.service";
import { DataTableOption } from "@/types/data-table.type";

interface TimeSheetReportFileModuleInterface {
  timeSheetReportFiles: TimeSheetReportFileInterface[];
  filterDateRange: string[];
  filterProjects: number[];
}

@Module({
  dynamic: true,
  name: "timeSheetReportFile",
  store,
})
class TimeSheetReportFileModule extends VuexModule implements TimeSheetReportFileModuleInterface {
  public timeSheetReportFiles: TimeSheetReportFileInterface[] = [];
  public filterDateRange: string[] = [];
  public filterProjects: number[] = [];
  public timeSheetReportCount: number = 0;

  @MutationAction({ mutate: ["timeSheetReportFiles", "timeSheetReportCount"] })
  public async getTimeSheetReportFiles(options?: DataTableOption) {
    const state = { ...(this.state as TimeSheetReportFileModuleInterface) };
    const { filterDateRange, filterProjects } = state;
    const timeSheetReportFiles = await getTimeSheetReportFiles({
      dateRange: filterDateRange,
      projects: filterProjects,
      options,
    });
    return { timeSheetReportFiles: timeSheetReportFiles.results, timeSheetReportCount: timeSheetReportFiles.count };
  }

  @MutationAction({ mutate: ["filterDateRange"] })
  public async updateFilterDateRange(dateRange: string[]) {
    const filterDateRange = [...dateRange];
    filterDateRange.sort();
    return { filterDateRange };
  }

  @MutationAction({ mutate: ["filterProjects"] })
  public async updateFilterProjects(filterProjects: number[]) {
    return { filterProjects };
  }

  @MutationAction({ mutate: ["timeSheetReportFiles"] })
  public async deleteTimeSheetReportFile(id: number) {
    await deleteReport(id);
    const state = { ...(this.state as TimeSheetReportFileModuleInterface) };
    const { timeSheetReportFiles } = state;
    const deletedIndex: number = timeSheetReportFiles.findIndex((item) => item.id === id);
    // found
    if (deletedIndex !== -1) {
      timeSheetReportFiles.splice(deletedIndex, 1);
    }
    return { timeSheetReportFiles };
  }
}

export default getModule(TimeSheetReportFileModule);
