





















































import { Component, Vue } from "vue-property-decorator";
import { ProjectInterface } from "@/types/project.type";
import { ProjectModule } from "@/store/modules/project-module";
import TimeSheetReportFileModule from "@/store/modules/time-sheet-report-file-module";
import Header from "@/components/Header.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";

@Component({
  components: {
    Header,
    SaveButton,
  },
})
export default class TimeSheetReportFilter extends Vue {
  private dateRangeOpen: boolean = false;

  private dateRules = [(v: any) => v.includes("~") || "You must select Start month and End month"];

  private get projects(): ProjectInterface[] {
    return ProjectModule.projects;
  }

  private getTimeSheetReportFile() {
    TimeSheetReportFileModule.getTimeSheetReportFiles();
  }

  private get dateRangeText() {
    const filterDateRange = [...this.filterDateRange];
    return filterDateRange.join(" ~ ");
  }

  private get filterDateRange() {
    return TimeSheetReportFileModule.filterDateRange;
  }

  private set filterDateRange(value) {
    TimeSheetReportFileModule.updateFilterDateRange(value);
  }

  private get filterProjects() {
    return TimeSheetReportFileModule.filterProjects;
  }

  private set filterProjects(value) {
    TimeSheetReportFileModule.updateFilterProjects(value);
  }
}
