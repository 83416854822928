































































































import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { ProjectInterface } from "@/types/project.type";
import { ProjectModule } from "@/store/modules/project-module";
import TimeSheetReportFileModule from "@/store/modules/time-sheet-report-file-module";
import { checkPendingTimesheet, createReport } from "@/services/time-sheet-report-file.service";
import { VuetifyForm } from "@/types/vuetify.type";
import moment from "moment";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { TimeSheetReportFileInterface } from "@/types/time-sheet-report-file.type";
import Header from "@/components/Header.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";

@Component({
  components: {
    Header,
    SaveButton,
  },
})
export default class TimeSheetReportCreateForm extends Vue {
  private menu: boolean = false;
  private defaultTimeSheetReportFile: TimeSheetReportFileInterface = {
    project: null,
    date: "",
    clientName: "",
    includeNotBillable: false,
    lockTimesheet: true,
  };
  private timeSheetReportFile: TimeSheetReportFileInterface = { ...this.defaultTimeSheetReportFile };
  private valid: boolean = false;
  private doNotAllowCreate = true;

  private projectRule = [(v: any) => !!v || "Project is required"];
  private monthRule = [(v: any) => !!v || "Report month is required"];

  @Ref()
  private form!: VuetifyForm;

  private get projects(): ProjectInterface[] {
    return ProjectModule.projects;
  }

  private async createReport() {
    this.form.validate();
    if (!this.valid) {
      await SnackbarModule.setSnack({
        color: "error",
        message: " Please fill in all the fields",
      });
      return;
    }
    try {
      await createReport(this.timeSheetReportFile);
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
      return;
    }
    this.timeSheetReportFile = { ...this.defaultTimeSheetReportFile };
    this.form.resetValidation();
    await SnackbarModule.setSnack({
      color: "success",
      message: "Create report successfully!",
    });
    await TimeSheetReportFileModule.getTimeSheetReportFiles();
  }

  private get currentMonth(): string {
    return moment().format("YYYY-MM");
  }

  @Watch("timeSheetReportFile", { immediate: true, deep: true })
  private async onTimeSheetReportFileChanged(newVal: TimeSheetReportFileInterface) {
    if (!newVal.date) {
      return;
    }
    if (!newVal.project) {
      return;
    }
    const result: any = await checkPendingTimesheet(newVal);
    this.doNotAllowCreate = result.hasPendingTimesheet;
  }
}
