






















































import { Component, Vue } from "vue-property-decorator";
import TimeSheetReportCreateForm from "@/components/time-sheet-report-file/TimeSheetReportCreateForm.vue";
import TimeSheetReportTable from "@/components/time-sheet-report-file/TimeSheetReportTable.vue";
import TimeSheetReportFilter from "@/components/time-sheet-report-file/TimeSheetReportFilter.vue";
import LeaveApprovalForm from "@/components/leave-request/LeaveApprovalForm.vue";
import InfoComponent from "@/components/InfoComponent.vue";
import { DataTableHeader } from "vuetify";
import TableComponent from "@/components/TableComponent.vue";
import { LeaveRequestModule } from "@/store/modules/leave-request-module";
import { getLeaveRequest } from "@/services/leave-request.service";
import { LeaveRequestInterface } from "@/types/leave-request.type";

@Component({
  components: {
    TimeSheetReportCreateForm,
    TimeSheetReportFilter,
    TimeSheetReportTable,
    LeaveApprovalForm,
    InfoComponent,
    TableComponent,
  },
})
export default class LeaveRequestApproval extends Vue {
  public items = [
    {
      text: "Leave Request Admin",
      exact: true,
      disabled: false,
      to: "leave-request-admin/",
    },
  ];

  get leaveRequestHeaders(): DataTableHeader[] {
    return [
      {
        text: "Date",
        value: "date",
      },
      {
        text: "User",
        value: "user",
      },
      {
        text: "Reason",
        value: "reason",
      },
      {
        text: "Notes / Comment",
        value: "notes",
      },
    ];
  }

  private leaveRequest: LeaveRequestInterface = {
    reason: 1,
    startDate: "",
    endDate: "",
    notes: "",
    duration: "full-day",
  };

  private back() {
    this.$router.push({ name: "Leave Request Admin" });
  }

  private async created() {
    this.leaveRequest = await getLeaveRequest(Number(this.$route.params.id));

    if (this.leaveRequest.approvedBy) {
      this.back();
    }

    this.items.push({
      text: this.leaveRequest.createdByInfo!.userDisplay!,
      exact: true,
      disabled: true,
      to: "",
    });
    await LeaveRequestModule.getAllLeaveCounts(this.leaveRequest.createdByInfo!.id!);
  }
  private get allLeaveCounts(): object {
    return LeaveRequestModule.allLeaveCounts;
  }
}
