import axios from "@/plugins/axios-setting";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import {
  TimeSheetReportFileInterface,
  TimeSheetReportFileRequestInterface,
  TimeSheetReportPaginationResponseInterface,
} from "@/types/time-sheet-report-file.type";
import { formatOrdering } from "@/utils/datatableUtils";

export function createReport(data: TimeSheetReportFileInterface): Promise<TimeSheetReportFileInterface> {
  return axios.post("/api/time-sheet-monthly-report/", data);
}

export function checkPendingTimesheet(data: TimeSheetReportFileInterface): Promise<any> {
  return axios.get(`/api/time-sheet-monthly-report/check_pending_timesheet/?project=${data.project}&date=${data.date}`);
}

export function getTimeSheetReportFiles(
  data: TimeSheetReportFileRequestInterface
): Promise<TimeSheetReportPaginationResponseInterface> {
  const { dateRange, projects } = data;
  const searchParams = new URLSearchParams();
  if (dateRange.length > 0) {
    const dateParams = ["date_after", "date_before"];
    dateRange.forEach((value, index) => {
      searchParams.set(dateParams[index], value);
    });
  }
  if (projects.length > 0) {
    searchParams.set("project", projects.join(","));
  }
  searchParams.set("logged", "true");

  if (data.options) {
    searchParams.append("itemsPerPage", String(data.options.itemsPerPage));
    searchParams.append("page", String(data.options.page));
    searchParams.append("ordering", formatOrdering(data.options));
  }

  return axios.get("/api/time-sheet-monthly-report/", { params: searchParams });
}

export function downloadReport(id: number): Promise<AxiosResponse> {
  const url = `/api/time-sheet-monthly-report/${id}/`;
  const config: AxiosRequestConfig = {
    responseType: "blob",
  };
  return axios.get(url, config);
}

export function deleteReport(id: number): Promise<void> {
  const url = `/api/time-sheet-monthly-report/${id}/`;
  return axios.delete(url);
}
